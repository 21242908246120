import React from 'react';

import { View, PackageItem, Option } from '../../types';
import { RepositoryContext } from '../../App';
import { ViewId } from '../../enums';
import OptionItem from './OptionItem/OptionItem';

type Props = {
    view: View;
    items: PackageItem[];
    setItems: (items: PackageItem[]) => void;
};

const defaultOptions: Option[] = [
    {
        id: 'sun-and-care',
        type: 'flat',
        title: 'Sun & Care',
        description:
            'Immer dabei: Lebenslange Fernwartung und Abwicklung von Service-Fallen mit den Herstellern.',
        pdfInfo: [
            {
                text: 'Einrichtung Sun & Care inkl. Monitoring, Fernwartung sowie telefonischer Support und Abwicklung etwaiger Garantiefälle für die gesamte Laufzeit des unveränderten PV-Systems (erlischt mit Austausch oder Ergänzung einzelner Komponenten sofern dies nicht durch CALO.SOL geschieht)'
            }
        ],
        price: 390,
        taxPercent: 19,
        disableIds: ['*']
    },
    {
        id: 'subhub',
        type: 'flat',
        title: 'SUNHUB Pro 1 Jahr gratis',
        description:
            'Immer dabei: Ein Jahr Pro-Lizenz für den SUNHUB - Intelligentes Energiemanagement für zuhause.',
        pdfInfo: [
            {
                text: 'Gutschein-Code für ein Jahr Pro-Lizenz zur Gratis-Nutzung des intelligenten Energiemanagementsystems SUNHUB.'
            },
            {
                text: 'Es gelten die allgemeinen Leistungsmerkmale und Beschreibungen zum SUNHUB. Nach Ablauf des Jahres kann jederzeit eine Lizenz in der App oder vergünstigt als Gutschein-Code gebucht werden. +++ keine automatische Verlängerung +++'
            },
            {
                text: 'Für die Nutzung des SUNHUB ist die Installation der für Android und iOS verfügbaren mobilen APP für Smartphones und eine gesonderte Registrierung über die APP erforderlich.'
            },
            {
                text: 'Für die Nutzung gelten gesonderte AGB und Datenschutzhinweise, die im Rahmen der Registrierung zur Nutzung des SUNHUB akzeptiert werden müssen und bekannt gegeben werden.'
            }
        ],
        price: 0,
        taxPercent: 0,
        disableIds: ['*']
    }
];

const getOptionsFromItems = (items: PackageItem[]): Option[] => {
    const optionsCard = items.find((item) => item.viewId === ViewId.OPTIONS);
    if (!optionsCard) return [...defaultOptions];

    const existingOptions = optionsCard?.options as Option[];
    if (existingOptions.find((opt) => opt.id === defaultOptions[0].id)) {
        return optionsCard.options as Option[];
    }

    return [...existingOptions, ...defaultOptions];
};

const OptionsView = (props: Props) => {
    const [selectedOptions, setSelectedOptions] = React.useState<Option[]>([
        ...getOptionsFromItems(props.items)
    ]);
    const { repository } = React.useContext(RepositoryContext);

    const [showExpert, setShowExpert] = React.useState<boolean>(false);

    const simpleCategory = repository.optionCategories.find((cat) => cat.type === 'simple');
    const expertCategory = repository.optionCategories.find((cat) => cat.type === 'expert');

    const units = React.useMemo(() => {
        const mod = props.items.find((item) => item.viewId === ViewId.MODULE);
        return mod?.units;
    }, [props.items]);

    const newPackage: PackageItem = React.useMemo(() => {
        const totalOptionsPrice = selectedOptions
            .map((opt) => opt.totalPrice ?? 0)
            .reduce((sum, price) => sum + price, 0);

        return {
            cardId: 'options-card',
            viewId: ViewId.OPTIONS,
            price: totalOptionsPrice,
            options: [...selectedOptions]
        };
    }, [selectedOptions]);

    const selectedCardIds = React.useMemo(
        () => props.items.map((item) => item.cardId),
        [props.items]
    );

    React.useEffect(() => {
        const newItems = props.items.filter((item) => item.viewId !== ViewId.OPTIONS);
        props.setItems([...newItems, newPackage]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPackage]);

    return (
        <div>
            <div className="row">
                <div className="col-xs-12 col-lg-11">
                    <div className="configuratorContentOptions">
                        {simpleCategory?.items.map((option, index) => {
                            const intersection = option?.disableIds.filter(
                                (disableId) =>
                                    selectedCardIds.includes(disableId) ||
                                    selectedOptions.map((option) => option.id).includes(disableId)
                            );
                            return (
                                <OptionItem
                                    key={index}
                                    units={units}
                                    option={option}
                                    selectedOptions={selectedOptions}
                                    isDisabled={
                                        intersection.length > 0 || option?.disableIds.includes('*')
                                    }
                                    setSelectedOptions={setSelectedOptions}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="row clearfloat">
                <div className="col-xs-12 col-lg-11">
                    <div className="configuratorContentOptions slidecontent">
                        <div className="slide_container">
                            <div
                                className={`slidecontent_headline ${showExpert ? 'active' : ''}`}
                                onClick={() => setShowExpert(!showExpert)}>
                                Zusatzleistungen
                            </div>
                            {showExpert && (
                                <div
                                    className={`${
                                        showExpert ? '' : 'slidecontent_content_container'
                                    }`}>
                                    <div className="marginBottom--small">
                                        Ob diese Zusatzleistungen für Sie und Ihr Haus zutreffen,
                                        besprechen Sie am besten in einem persönlichen
                                        Beratungsgespräch. Für Ihren <b>ersten</b> Kostenvoranschlag
                                        sind diese <b>nicht notwendig</b>.
                                    </div>
                                    {expertCategory?.items.map((option, index) => {
                                        const intersection = option.disableIds.filter(
                                            (disableId) =>
                                                selectedCardIds.includes(disableId) ||
                                                selectedOptions
                                                    .map((option) => option.id)
                                                    .includes(disableId)
                                        );
                                        return (
                                            <OptionItem
                                                key={index}
                                                units={units}
                                                option={option}
                                                selectedOptions={selectedOptions}
                                                isDisabled={intersection.length > 0}
                                                setSelectedOptions={setSelectedOptions}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OptionsView;
