import React from 'react';
import { formatPrice } from '../../../utils';
import { Card, PackageItem, View } from '../../../types';

type Props = {
    index: number;
    item: PackageItem;
    card: Card;
    view: View;
    setView: (view: View) => void;
};

const SidebarItem = (props: Props) => {
    const optionText = React.useMemo(() => {
        if (props.item.options?.length && 'label' in props.item.options[0]) {
            return ` (${props.item.options[0].label})`;
        }
        return '';
    }, [props.item]);

    return (
        <div className="overviewItem" key={props.index}>
            <div className="overviewItem__image">
                <img src={props.card.data.image} width="90px" height="90px" />
            </div>
            <div className="overviewItem__content">
                <div className="overviewItem__quantity">
                    {props.item.units ? `${props.item.units}x` : '1x'}
                </div>
                <div className="overviewItem__description">
                    {props.card.data.typeDescription}
                    {optionText}
                </div>
                {window.location.href.includes('angebot') && (
                    <div className="overviewItem__price">{formatPrice(props.item.price ?? 0)}</div>
                )}
            </div>
        </div>
    );
};

export default SidebarItem;
